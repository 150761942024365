import React from "react";
import { Parallax } from "react-parallax";
import bgImage from "../../images/join-bg.jpg";

const JoinModule = () => {
  return (
    <Parallax className="join-module-container" bgImage={bgImage} strength={400}>
      <div style={{height: '50vh', minHeight: 500}}>
          <div className="join-module-inner">
              <h3>Together we can<br />grow our programs.</h3>
              <a href="/donate" className="no-effects">
                  <div className="btn primary">
                      <span>Donate</span>
                  </div>
              </a>
          </div>
      </div>
  </Parallax>
  )
};

export default JoinModule;

import React, { useState, useRef } from "react";
import Slider from 'react-slick-animated-goto';
import { lightenDarkenColor } from "../../utils";
import iconGlobe from "../../images/icon-globe.svg";
import iconRecycling from "../../images/icon-recycling.svg";
import iconTrain from "../../images/icon-train.svg";
import iconTransport from "../../images/icon-transport.svg";
import iconFilm from "../../images/icon-film.svg";
import iconEnergy from "../../images/icon-energy.svg";
import iconWater from "../../images/icon-water.svg";
import iconPaintbrush from "../../images/icon-paintbrush.svg";
import iconMedical from "../../images/icon-medical2.svg";


const AboutTimeline = () => {
    const [activeButton, setActiveButton] = useState(0);
    const slider = useRef<typeof Slider | null>(null);
    
    const gallerySettings = {
      dots: false,
      infinite: true,
      arrows: true,
      beforeChange: (undefined, nextSlide) => {
        setActiveButton(nextSlide);
      }
    };

    const jumpToYear = (int: number) => {
      slider.current.slickGoTo(int, false);
      setTimeout(() => {
        setActiveButton(int);
      }, 500);
    };

    const checkActive = (num: number) => {

    };

    const timelineOver = (e) => {
      let xRel = e.screenX;
      let yRel = e.screenY;
      let width = 100;

      let yAngle = -(0.5 - (xRel / width)) * 40;
      let xAngle = (0.5 - (yRel / width)) * 40;

      if (yAngle > 15) {
          yAngle = 15;
      }

      if (yAngle < -15) {
          yAngle = -15;
      }

      if (xAngle < 350) {
          xAngle = 350;
      }

      if (xAngle > 360) {
          xAngle = 360;
      }

     // document.getElementsByClassName("about-timeline-dot.active about-cube")[0].setAttribute("style", "transform: `rotateX(${xAngle}deg) rotateY(${yAngle}deg)`");
    };


    return (
      <div className="about-timeline-container" onMouseMove={(e) => timelineOver(e)}>
          <div className={`about-timeline-container-background bg-0${activeButton === 0 ? ' active' : ''}`} style={{ background: 'radial-gradient(ellipse at center, #9FD8E3 0%, ' + lightenDarkenColor({ col: "#9FD8E3", amt: -28 }) + ' 100%)' }} />
          <div className={`about-timeline-container-background bg-1${activeButton === 1 ? ' active' : ''}`} style={{ background: 'radial-gradient(ellipse at center, #a187c7 0%, ' + lightenDarkenColor({ col: '#a187c7', amt: -28}) + ' 100%)' }} />
          <div className={`about-timeline-container-background bg-2${activeButton === 2 ? ' active' : ''}`} style={{ background: 'radial-gradient(ellipse at center, #ffc57c 0%, ' + lightenDarkenColor({ col: '#ffc57c', amt: -28}) + ' 100%)' }} />
          <div className={`about-timeline-container-background bg-3${activeButton === 3 ? ' active' : ''}`} style={{ background: 'radial-gradient(ellipse at center, #9cf580 0%, ' + lightenDarkenColor({ col: '#9cf580', amt: -28}) + ' 100%)' }} />
          <div className={`about-timeline-container-background bg-4${activeButton === 4 ? ' active' : ''}`} style={{ background: 'radial-gradient(ellipse at center, #e89081 0%, ' + lightenDarkenColor({ col: '#e89081', amt: -28}) + ' 100%)' }} />
          <div className={`about-timeline-container-background bg-5${activeButton === 5 ? ' active' : ''}`} style={{ background: 'radial-gradient(ellipse at center, #8280fb 0%, ' + lightenDarkenColor({ col: '#8280fb', amt: -28}) + ' 100%)' }} />
          <div className={`about-timeline-container-background bg-6${activeButton === 6 ? ' active' : ''}`} style={{ background: 'radial-gradient(ellipse at center, #a187c7 0%, ' + lightenDarkenColor({ col: '#a187c7', amt: -28}) + ' 100%)' }} />
          <div className={`about-timeline-container-background bg-7${activeButton === 7 ? ' active' : ''}`} style={{ background: 'radial-gradient(ellipse at center, #ffc57c 0%, ' + lightenDarkenColor({ col: '#ffc57c', amt: -28}) + ' 100%)' }} />
          <div className={`about-timeline-container-background bg-8${activeButton === 8 ? ' active' : ''}`} style={{ background: 'radial-gradient(ellipse at center, #9cf580 0%, ' + lightenDarkenColor({ col: '#9cf580', amt: -28}) + ' 100%)' }} />
          <div className={`about-timeline-container-background bg-9${activeButton === 9 ? ' active' : ''}`} style={{ background: 'radial-gradient(ellipse at center, #e89081 0%, ' + lightenDarkenColor({ col: '#e89081', amt: -28}) + ' 100%)' }} />
          <div className={`about-timeline-container-background bg-10${activeButton === 10 ? ' active' : ''}`} style={{ background: 'radial-gradient(ellipse at center, #8280fb 0%, ' + lightenDarkenColor({ col: '#8280fb', amt: -28}) + ' 100%)' }} />
          <div className={`about-timeline-container-background bg-11${activeButton === 11 ? ' active' : ''}`} style={{ background: 'radial-gradient(ellipse at center, #a187c7 0%, ' + lightenDarkenColor({ col: '#a187c7', amt: -28}) + ' 100%)' }} />
          <div className={`about-timeline-container-background bg-12${activeButton === 12 ? ' active' : ''}`} style={{ background: 'radial-gradient(ellipse at center, #ffc57c 0%, ' + lightenDarkenColor({ col: '#ffc57c', amt: -28}) + ' 100%)' }} />
          <div className={`about-timeline-container-background bg-13${activeButton === 13 ? ' active' : ''}`} style={{ background: 'radial-gradient(ellipse at center, #9cf580 0%, ' + lightenDarkenColor({ col: '#9cf580', amt: -28}) + ' 100%)' }} />

          <h1 className="about-timeline-header">A Little History</h1>
          <div className="about-year-slick-container">
              <Slider ref={slider} {...gallerySettings} className="about-slider">
                  <div className="about-year-container">
                      <div className="about-year">2002</div>
                      <div className="about-year-text">
                          <h1>Global Inheritance founded.</h1>
                      </div>
                  </div>
                  <div className="about-year-container">
                      <div className="about-year">2003</div>
                      <div className="about-year-text">
                          <h1>First TRASHed Art of Recycling, merging creativity and visual arts with issue of waste reduction.</h1>
                          <h1>Fashion Peace launched.</h1>
                      </div>
                  </div>
                  <div className="about-year-container">
                      <div className="about-year">2004</div>
                      <div className="about-year-text">
                          <h1>Art of Recycling + Recycling Store launched.</h1>
                      </div>
                  </div>
                  <div className="about-year-container">
                      <div className="about-year">2006</div>
                      <div className="about-year-text">
                          <h1>Tour Rider is launched, addressing traffic congestion and pollution problem.</h1>
                          <h1>Public Display of Affection launched.</h1>
                      </div>
                  </div>
                  <div className="about-year-container">
                      <div className="about-year">2007</div>
                      <div className="about-year-text">
                          <h1>Carpoolchella launched.</h1>
                          <h1>Energy FACTory/Energy Playground launched at Coachella to educate others about energy sources.</h1>
                      </div>
                  </div>
                  <div className="about-year-container">
                      <div className="about-year">2008</div>
                      <div className="about-year-text">
                          <h1>The Bigger Picture Film Awards launched.</h1>
                      </div>
                  </div>
                  <div className="about-year-container">
                      <div className="about-year">2010</div>
                      <div className="about-year-text">
                          <h1>Energy FACTory DJ Mixer launched.</h1>
                      </div>
                  </div>
                  <div className="about-year-container">
                      <div className="about-year">2013</div>
                      <div className="about-year-text">
                          <h1>In light of the imminent drought California is facing, Oasis started as an awareness program.</h1>
                      </div>
                  </div>
                  <div className="about-year-container">
                      <div className="about-year">2016</div>
                      <div className="about-year-text">
                          <h1>Rain Supreme is launched to educated about California's drought.</h1>
                      </div>
                  </div>
                  <div className="about-year-container">
                      <div className="about-year">2017</div>
                      <div className="about-year-text">
                          <h1>POSTed Studio launched.</h1>
                      </div>
                  </div>
                  <div className="about-year-container">
                      <div className="about-year">2019</div>
                      <div className="about-year-text">
                          <h1>Plastic Surgery and BearTraxx launched.</h1>
                      </div>
                  </div>
                  <div className="about-year-container">
                      <div className="about-year">2020</div>
                      <div className="about-year-text">
                          <h1>Truth &amp; Dare launched.</h1>
                      </div>
                  </div>
                  <div className="about-year-container">
                      <div className="about-year">2022</div>
                      <div className="about-year-text">
                          <h1>Meat the Beetles launched.</h1>
                      </div>
                  </div>
                  <div className="about-year-container">
                      <div className="about-year">2023</div>
                      <div className="about-year-text">
                          <h1>Art of Thrifting launched.</h1>
                      </div>
                  </div>
              </Slider>
          </div>

          <div className="about-timeline-inner">
              <div className="about-timeline-bar-container">
                  <div className="about-timeline-bar">
                      <div className={`about-timeline-dot ${checkActive(0)}`} onClick={() => jumpToYear(0)}>
                          <div className="about-cube-container">
                              <div className="about-cube">
                                  <div className="about-cube-face cube-face-front" style={{backgroundImage: `url(${iconGlobe})`}}></div>
                                  <div className="about-cube-face cube-face-back"></div>
                                  <div className="about-cube-face cube-face-left"></div>
                                  <div className="about-cube-face cube-face-right"></div>
                                  <div className="about-cube-face cube-face-top"></div>
                                  <div className="about-cube-face cube-face-bottom"></div>
                              </div>
                          </div>
                          <div className="about-timeline-dot-year">
                              2002
                              <svg viewBox="0 0 2 20">
                                  <path d="M1,0v20"/>
                              </svg>
                          </div>
                      </div>
                      <div className={`about-timeline-dot ${checkActive(1)}`} onClick={() => jumpToYear(1)}>
                          <div className="about-cube-container">
                              <div className="about-cube">
                                  <div className="about-cube-face cube-face-front" style={{backgroundImage: `url(${iconRecycling})`}}></div>
                                  <div className="about-cube-face cube-face-back"></div>
                                  <div className="about-cube-face cube-face-left"></div>
                                  <div className="about-cube-face cube-face-right"></div>
                                  <div className="about-cube-face cube-face-top"></div>
                                  <div className="about-cube-face cube-face-bottom"></div>
                              </div>
                          </div>
                          <div className="about-timeline-dot-year">
                              2003
                              <svg viewBox="0 0 2 20">
                                  <path d="M1,0v20"/>
                              </svg>
                          </div>
                      </div>
                      <div className={`about-timeline-dot ${checkActive(2)}`} onClick={() => jumpToYear(2)}>
                          <div className="about-cube-container">
                              <div className="about-cube">
                                  <div className="about-cube-face cube-face-front" style={{backgroundImage: `url(${iconRecycling})`}}></div>
                                  <div className="about-cube-face cube-face-back"></div>
                                  <div className="about-cube-face cube-face-left"></div>
                                  <div className="about-cube-face cube-face-right"></div>
                                  <div className="about-cube-face cube-face-top"></div>
                                  <div className="about-cube-face cube-face-bottom"></div>
                              </div>
                          </div>
                          <div className="about-timeline-dot-year">
                              2004
                              <svg viewBox="0 0 2 20">
                                  <path d="M1,0v20"/>
                              </svg>
                          </div>
                      </div>
                      <div className={`about-timeline-dot ${checkActive(3)}`} onClick={() => jumpToYear(3)}>
                          <div className="about-cube-container">
                              <div className="about-cube">
                                  <div className="about-cube-face cube-face-front" style={{backgroundImage: `url(${iconTrain})`}}></div>
                                  <div className="about-cube-face cube-face-back"></div>
                                  <div className="about-cube-face cube-face-left"></div>
                                  <div className="about-cube-face cube-face-right"></div>
                                  <div className="about-cube-face cube-face-top"></div>
                                  <div className="about-cube-face cube-face-bottom"></div>
                              </div>
                          </div>
                          <div className="about-timeline-dot-year">
                              2006
                              <svg viewBox="0 0 2 20">
                                  <path d="M1,0v20"/>
                              </svg>
                          </div>
                      </div>
                      <div className={`about-timeline-dot ${checkActive(4)}`} onClick={() => {jumpToYear(4)}}>
                          <div className="about-cube-container">
                              <div className="about-cube">
                                  <div className="about-cube-face cube-face-front" style={{backgroundImage: `url(${iconTransport})`}}></div>
                                  <div className="about-cube-face cube-face-back"></div>
                                  <div className="about-cube-face cube-face-left"></div>
                                  <div className="about-cube-face cube-face-right"></div>
                                  <div className="about-cube-face cube-face-top"></div>
                                  <div className="about-cube-face cube-face-bottom"></div>
                              </div>
                          </div>
                          <div className="about-timeline-dot-year">
                              2007
                              <svg viewBox="0 0 2 20">
                                  <path d="M1,0v20"/>
                              </svg>
                          </div>
                      </div>
                      <div className={`about-timeline-dot ${checkActive(5)}`} onClick={() => {jumpToYear(5)}}>
                          <div className="about-cube-container">
                              <div className="about-cube">
                                  <div className="about-cube-face cube-face-front" style={{backgroundImage: `url(${iconFilm})`}}></div>
                                  <div className="about-cube-face cube-face-back"></div>
                                  <div className="about-cube-face cube-face-left"></div>
                                  <div className="about-cube-face cube-face-right"></div>
                                  <div className="about-cube-face cube-face-top"></div>
                                  <div className="about-cube-face cube-face-bottom"></div>
                              </div>
                          </div>
                          <div className="about-timeline-dot-year">
                              2008
                              <svg viewBox="0 0 2 20">
                                  <path d="M1,0v20"/>
                              </svg>
                          </div>
                      </div>
                      <div className={`about-timeline-dot ${checkActive(6)}`} onClick={() => {jumpToYear(6)}}>
                          <div className="about-cube-container">
                              <div className="about-cube">
                                  <div className="about-cube-face cube-face-front" style={{backgroundImage: `url(${iconEnergy})`}}></div>
                                  <div className="about-cube-face cube-face-back"></div>
                                  <div className="about-cube-face cube-face-left"></div>
                                  <div className="about-cube-face cube-face-right"></div>
                                  <div className="about-cube-face cube-face-top"></div>
                                  <div className="about-cube-face cube-face-bottom"></div>
                              </div>
                          </div>
                          <div className="about-timeline-dot-year">
                              2010
                              <svg viewBox="0 0 2 20">
                                  <path d="M1,0v20"/>
                              </svg>
                          </div>
                      </div>
                      <div className={`about-timeline-dot ${checkActive(7)}`} onClick={() => {jumpToYear(7)}}>
                          <div className="about-cube-container">
                              <div className="about-cube">
                                  <div className="about-cube-face cube-face-front" style={{backgroundImage: `url(${iconWater})`}}></div>
                                  <div className="about-cube-face cube-face-back"></div>
                                  <div className="about-cube-face cube-face-left"></div>
                                  <div className="about-cube-face cube-face-right"></div>
                                  <div className="about-cube-face cube-face-top"></div>
                                  <div className="about-cube-face cube-face-bottom"></div>
                              </div>
                          </div>
                          <div className="about-timeline-dot-year">
                              2013
                              <svg viewBox="0 0 2 20">
                                  <path d="M1,0v20"/>
                              </svg>
                          </div>
                      </div>
                      <div className={`about-timeline-dot ${checkActive(8)}`} onClick={() => {jumpToYear(8)}}>
                          <div className="about-cube-container">
                              <div className="about-cube">
                                  <div className="about-cube-face cube-face-front" style={{backgroundImage: `url(${iconWater})`}}></div>
                                  <div className="about-cube-face cube-face-back"></div>
                                  <div className="about-cube-face cube-face-left"></div>
                                  <div className="about-cube-face cube-face-right"></div>
                                  <div className="about-cube-face cube-face-top"></div>
                                  <div className="about-cube-face cube-face-bottom"></div>
                              </div>
                          </div>
                          <div className="about-timeline-dot-year">
                              2016
                              <svg viewBox="0 0 2 20">
                                  <path d="M1,0v20"/>
                              </svg>
                          </div>
                      </div>
                      <div className={`about-timeline-dot ${checkActive(9)}`} onClick={() => {jumpToYear(9)}}>
                          <div className="about-cube-container">
                              <div className="about-cube">
                                  <div className="about-cube-face cube-face-front" style={{backgroundImage: `url(${iconPaintbrush})`}}></div>
                                  <div className="about-cube-face cube-face-back"></div>
                                  <div className="about-cube-face cube-face-left"></div>
                                  <div className="about-cube-face cube-face-right"></div>
                                  <div className="about-cube-face cube-face-top"></div>
                                  <div className="about-cube-face cube-face-bottom"></div>
                              </div>
                          </div>
                          <div className="about-timeline-dot-year">
                              2017
                              <svg viewBox="0 0 2 20">
                                  <path d="M1,0v20"/>
                              </svg>
                          </div>
                      </div>
                      <div className={`about-timeline-dot ${checkActive(10)}`} onClick={() => {jumpToYear(10)}}>
                          <div className="about-cube-container">
                              <div className="about-cube">
                                  <div className="about-cube-face cube-face-front" style={{backgroundImage: `url(${iconMedical})`}}></div>
                                  <div className="about-cube-face cube-face-back"></div>
                                  <div className="about-cube-face cube-face-left"></div>
                                  <div className="about-cube-face cube-face-right"></div>
                                  <div className="about-cube-face cube-face-top"></div>
                                  <div className="about-cube-face cube-face-bottom"></div>
                              </div>
                          </div>
                          <div className="about-timeline-dot-year">
                              2019
                              <svg viewBox="0 0 2 20">
                                  <path d="M1,0v20"/>
                              </svg>
                          </div>
                      </div>
                      <div className={`about-timeline-dot ${checkActive(11)}`} onClick={() => {jumpToYear(11)}}>
                          <div className="about-cube-container">
                              <div className="about-cube">
                                  <div className="about-cube-face cube-face-front" style={{backgroundImage: `url(${iconGlobe})`}}></div>
                                  <div className="about-cube-face cube-face-back"></div>
                                  <div className="about-cube-face cube-face-left"></div>
                                  <div className="about-cube-face cube-face-right"></div>
                                  <div className="about-cube-face cube-face-top"></div>
                                  <div className="about-cube-face cube-face-bottom"></div>
                              </div>
                          </div>
                          <div className="about-timeline-dot-year">
                              2020
                              <svg viewBox="0 0 2 20">
                                  <path d="M1,0v20"/>
                              </svg>
                          </div>
                      </div>
                      <div className={`about-timeline-dot ${checkActive(12)}`} onClick={() => {jumpToYear(12)}}>
                          <div className="about-cube-container">
                              <div className="about-cube">
                                  <div className="about-cube-face cube-face-front" style={{backgroundImage: `url(${iconPaintbrush})`}}></div>
                                  <div className="about-cube-face cube-face-back"></div>
                                  <div className="about-cube-face cube-face-left"></div>
                                  <div className="about-cube-face cube-face-right"></div>
                                  <div className="about-cube-face cube-face-top"></div>
                                  <div className="about-cube-face cube-face-bottom"></div>
                              </div>
                          </div>
                          <div className="about-timeline-dot-year">
                              2022
                              <svg viewBox="0 0 2 20">
                                  <path d="M1,0v20"/>
                              </svg>
                          </div>
                      </div>
                      <div className={`about-timeline-dot ${checkActive(13)}`} onClick={() => {jumpToYear(13)}}>
                          <div className="about-cube-container">
                              <div className="about-cube">
                                  <div className="about-cube-face cube-face-front" style={{backgroundImage: `url(${iconRecycling})`}}></div>
                                  <div className="about-cube-face cube-face-back"></div>
                                  <div className="about-cube-face cube-face-left"></div>
                                  <div className="about-cube-face cube-face-right"></div>
                                  <div className="about-cube-face cube-face-top"></div>
                                  <div className="about-cube-face cube-face-bottom"></div>
                              </div>
                          </div>
                          <div className="about-timeline-dot-year">
                              2023
                              <svg viewBox="0 0 2 20">
                                  <path d="M1,0v20"/>
                              </svg>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    );
}

export default AboutTimeline;

import React from "react";
import { Parallax } from "react-parallax";
import Reveal from "react-reveal";
import AboutTimeline from '../components/AboutTimeline';
import { lightenDarkenColor } from "../utils";
import JoinModule from "../components/JoinModule";
import Layout from "../components/layouts";
import headerAbout from "../images/header-about.jpg";
import iconPeople from "../images/icon-people.svg";
import iconPaintbrush from "../images/icon-paintbrush.svg";
import iconGlobe from "../images/icon-globe.svg";
import iconTickets from "../images/icon-tickets.svg";


const About = () => {
    // componentDidMount() {
    //     $('nav .container').addClass('background');
    //     $('html').removeClass('scroll-check');
    //     $('html').addClass('nav-background');
    //     document.title = "Global Inheritance | About Us";
    //     $("meta[property='og\\:title']").attr("content", 'About');
    //     $("meta[property='og\\:url']").attr("content", this.context.siteUrl() + '/about/');
    // }

    return (
      <Layout navBg>
        <div className="about-page">
          <Parallax className="page-header" bgImage={headerAbout} strength={400}>
              <div style={{height: '100vh', maxHeight: '550px'}}>
                  <div className="page-header-overlay" />
                  <h1 className="page-header-title">About Us</h1>
              </div>
          </Parallax>
          <div className="about-body">
              <div className="about-body-inner">
                  <div className="about-text-container">
                      <Reveal effect="animated fadeInLeft">
                        <div className="about-text">
                          <h3 className="about-text-header">What We Do</h3>
                          <p>
                              Born in 2002, Global Inheritance is a 501(c)3 non-profit organization working to reinvent activism
                              and empower individuals to seek a global balance. Our initiatives focus on the power of creativity
                              to communicate and drive progressive social change.
                          </p>
                          <p>
                              Global Inheritance develops campaigns which cater specifically to individual demographic groups, targeting the message and experience to be approachable for various subcultures. With a focus on diversity and inclusion, Global Inheritance develops campaigns that cater to unreached demographic groups, targeting the message and experience to be approachable for various subcultures.
                          </p>
                        </div>
                      </Reveal>

                  </div>

                  <AboutTimeline />

                  <div className="page-impact-container">
                      <div className="page-impact-list">
                          <h1>Impact</h1>
                          <div className="page-impact-inner">
                              <div className="page-impact-modules">
                                  <Reveal effect="animated bounceIn">
                                    <div className="page-impact">
                                      <div className="page-impact-icon-container" style={{background: 'linear-gradient(-220deg, #FFC474 0%, ' + lightenDarkenColor({ col: '#FFC474', amt: -35 }) + ' 100%)'}}>
                                          <div className="page-impact-icon" style={{backgroundImage: `url(${iconPeople})`}} />
                                      </div>
                                      <div className="page-impact-number">20 Million</div>
                                      <div className="page-impact-desc">People Reached</div>
                                    </div>
                                      
                                  </Reveal>
                                  <Reveal effect="animated bounceIn">
                                    <div className="page-impact">
                                    <div className="page-impact-icon-container" style={{background: 'linear-gradient(-220deg, #FFC474 0%, ' + lightenDarkenColor({ col: '#FFC474', amt: -35 }) + ' 100%)'}}>
                                          <div className="page-impact-icon" style={{backgroundImage: `url(${iconPaintbrush})`}} />
                                      </div>
                                      <div className="page-impact-number">5000+</div>
                                      <div className="page-impact-desc">Artists & Volunteers</div>
                                    </div>
                                      
                                  </Reveal>
                                  <Reveal effect="animated bounceIn">
                                    <div className="page-impact">
                                    <div className="page-impact-icon-container" style={{background: 'linear-gradient(-220deg, #FFC474 0%, ' + lightenDarkenColor({ col: '#FFC474', amt: -35 }) + ' 100%)'}}>
                                          <div className="page-impact-icon" style={{backgroundImage: `url(${iconTickets})`}} />
                                      </div>
                                      <div className="page-impact-number">500+</div>
                                      <div className="page-impact-desc">Festivals & Events</div>
                                    </div>
                                     
                                  </Reveal>
                                  {/* <Reveal effect="animated bounceIn">
                                    <div className="page-impact">
                                    <div className="page-impact-icon-container" style={{background: 'linear-gradient(-220deg, #FFC474 0%, ' + lightenDarkenColor({ col: '#FFC474', amt: -35 }) + ' 100%)'}}>
                                          <div className="page-impact-icon" style={{backgroundImage: `url(${iconGlobe})`}} />
                                      </div>
                                      <div className="page-impact-number">31</div>
                                      <div className="page-impact-desc">States</div>
                                    </div>
                                      
                                  </Reveal> */}
                              </div>
                          </div>
                      </div>
                  </div>
                  <JoinModule />

                  {/* <Reveal effect="animated fadeInUp" className="about-executives">
                      <div className="home-section-title-container">
                          <h1 className="home-section-title exec">Executive Team</h1>
                          <span />
                      </div>
                      <Tilt className="tilter" options={{max: 25}}>
                          <div className="tilter-figure" >
                              <a href="mailto:ericritz@globalinheritance.org">
                                  <img className="tilter-image" src={this.context.assetUrl("/images/about-eric.jpg")} />
                                  <div className="tilter-deco tilter-deco-shine"><div></div></div>
                                  <div className="tilter-deco tilter-deco-overlay"></div>
                                  <figcaption className="tilter-caption">
                                      <h3 className="tilter-title">Eric Ritz</h3>
                                      <p className="tilter-description">Executive Director / Founder</p>
                                  </figcaption>
                                  <svg className="tilter-deco tilter-deco-lines" viewBox="0 0 300 300">
                                      <path d="M280,20V280H20V20H280z"/>
                                  </svg>
                              </a>
                          </div>
                      </Tilt>
                      <Tilt className="tilter" options={{max: 25}}>
                          <div className="tilter-figure" >
                              <a href="mailto:matt@globalinheritance.org">
                                  <img className="tilter-image" src={this.context.assetUrl("/images/about-matthew-2.jpg")} />
                                  <div className="tilter-deco tilter-deco-shine"><div></div></div>
                                  <div className="tilter-deco tilter-deco-overlay"></div>
                                  <figcaption className="tilter-caption">
                                      <h3 className="tilter-title">Mathew Brady</h3>
                                      <p className="tilter-description">Creative Director</p>
                                  </figcaption>
                                  <svg className="tilter-deco tilter-deco-lines" viewBox="0 0 300 300">
                                      <path d="M280,20V280H20V20H280z"/>
                                  </svg>
                              </a>
                          </div>
                      </Tilt>
                      <Tilt className="tilter" options={{max: 25}}>
                          <div className="tilter-figure" >
                              <a href="mailto:maggie@globalinheritance.org">
                                  <img className="tilter-image" src={this.context.assetUrl("/images/about-maggie.jpg")} />
                                  <div className="tilter-deco tilter-deco-shine"><div></div></div>
                                  <div className="tilter-deco tilter-deco-overlay"></div>
                                  <figcaption className="tilter-caption">
                                      <h3 className="tilter-title">Maggie Navarlatz Ritz</h3>
                                      <p className="tilter-description">Digital Manager</p>
                                  </figcaption>
                                  <svg className="tilter-deco tilter-deco-lines" viewBox="0 0 300 300">
                                      <path d="M280,20V280H20V20H280z"/>
                                  </svg>
                              </a>
                          </div>
                      </Tilt>
                      <Tilt className="tilter" options={{max: 25}}>
                          <div className="tilter-figure" >
                              <a href="mailto:info@globalinheritance.org">
                                  <img className="tilter-image" src={this.context.assetUrl("/images/about-lisa.jpg")} />
                                  <div className="tilter-deco tilter-deco-shine"><div></div></div>
                                  <div className="tilter-deco tilter-deco-overlay"></div>
                                  <figcaption className="tilter-caption">
                                      <h3 className="tilter-title">Lisa Chau</h3>
                                      <p className="tilter-description">Project Manager</p>
                                  </figcaption>
                                  <svg className="tilter-deco tilter-deco-lines" viewBox="0 0 300 300">
                                      <path d="M280,20V280H20V20H280z"/>
                                  </svg>
                              </a>
                          </div>
                      </Tilt>
                      <Tilt className="tilter" options={{max: 25}}>
                          <div className="tilter-figure" >
                              <a href="mailto:info@globalinheritance.org">
                                  <img className="tilter-image" src={this.context.assetUrl("/images/about-whitney.jpg")} />
                                  <div className="tilter-deco tilter-deco-shine"><div></div></div>
                                  <div className="tilter-deco tilter-deco-overlay"></div>
                                  <figcaption className="tilter-caption">
                                      <h3 className="tilter-title">Whitney Zimmerman</h3>
                                      <p className="tilter-description">Financial Manager</p>
                                  </figcaption>
                                  <svg className="tilter-deco tilter-deco-lines" viewBox="0 0 300 300">
                                      <path d="M280,20V280H20V20H280z"/>
                                  </svg>
                              </a>
                          </div>
                      </Tilt>
                      <Tilt className="tilter" options={{max: 25}}>
                          <div className="tilter-figure" >
                              <a href="mailto:info@globalinheritance.org">
                                  <img className="tilter-image" src={this.context.assetUrl("/images/about-jared.jpg")} />
                                  <div className="tilter-deco tilter-deco-shine"><div></div></div>
                                  <div className="tilter-deco tilter-deco-overlay"></div>
                                  <figcaption className="tilter-caption">
                                      <h3 className="tilter-title">Amanda Dunbar</h3>
                                      <p className="tilter-description">Event Manager</p>
                                  </figcaption>
                                  <svg className="tilter-deco tilter-deco-lines" viewBox="0 0 300 300">
                                      <path d="M280,20V280H20V20H280z"/>
                                  </svg>
                              </a>
                          </div>
                      </Tilt>
                  </Reveal> */}
              </div>
          </div>
      </div>
      </Layout>
  );

}

export default About;
